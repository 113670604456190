import React from "react";
import SimpleCard from "../components/common/SimpleCard";
// import BackgroundImage from "../components/common/BackgroundImage";
// import backgroundImagesRelativePath from "../../static/content/backgroundImages.yml";

export default function NotFoundPage({ backgroundImagesEdges }) {
  const message = "La page que vous cherchez n'existe pas";
  return (
    <>
      {/* <BackgroundImage backgroundImagesEdges={backgroundImagesEdges} backgroundImageRelativePath={backgroundImagesRelativePath.default} /> */}
      <SimpleCard message={message} />
    </>
  )
}
