import React from "react";
import CardContainer from "./CardContainer";
import Card from 'react-bootstrap/Card';

export default function (props) {
  return (
    <CardContainer>
      <Card.Body>
        <Card.Text>
          {props.message}
        </Card.Text>
      </Card.Body>
    </CardContainer>
  );
}